export const IMAGE_TYPES = {
  ALL: 'image/*',
  BMP: 'image/svg+xml',
  COD: 'image/cis-cod',
  CMX: 'image/x-cmx',
  GIF: 'image/gif',
  ICO: 'image/x-icon',
  IEF: 'image/ief',
  JFIF: 'image/pipeg',
  JPE: 'image/jpeg',
  JPEG: 'image/jpeg',
  JPG: 'image/jpeg',
  PBM: 'image/x-portable-bitmap',
  PGM: 'image/x-portable-graymap',
  PNM: 'image/x-portable-anymap',
  PNG: 'image/png',
  PPM: 'image/x-portable-pixmap',
  RAS: 'image/x-cmu-raster',
  RGB: 'image/x-rgb',
  SVG: 'image/svg+xml',
  TIF: 'image/tiff',
  TIFF: 'image/tiff',
  XBM: 'image/x-xbitmap',
  XPM: 'image/x-xpixmap',
  XWD: 'image/x-xwindowdump',

  '7Z': '.7z, application/x-7z-compressed',
  ZIP: '.zip, application/zip, application/x-zip-compressed',
  RAR: '.rar, application/vnd.rar, application/x-compressed',
};

export const IMAGE_TYPE_NAMES = {
  [IMAGE_TYPES.ALL]: ['.all'],
  [IMAGE_TYPES.BMP]: ['.bmp'],
  [IMAGE_TYPES.COD]: ['.cod'],
  [IMAGE_TYPES.CMX]: ['.cmx'],
  [IMAGE_TYPES.GIF]: ['.gif'],
  [IMAGE_TYPES.ICO]: ['.ico'],
  [IMAGE_TYPES.IEF]: ['.ief'],
  [IMAGE_TYPES.JFIF]: ['.jfif'],
  [IMAGE_TYPES.JPE]: ['.jpg', '.jpeg'],
  [IMAGE_TYPES.JPEG]: ['.jpg', '.jpeg'],
  [IMAGE_TYPES.JPG]: ['.jpg', '.jpeg'],
  [IMAGE_TYPES.PBM]: ['.pbm'],
  [IMAGE_TYPES.PGM]: ['.pgm'],
  [IMAGE_TYPES.PNM]: ['.pnm'],
  [IMAGE_TYPES.PNG]: ['.png'],
  [IMAGE_TYPES.PPM]: ['.ppm'],
  [IMAGE_TYPES.RAS]: ['.ras'],
  [IMAGE_TYPES.RGB]: ['.rgb'],
  [IMAGE_TYPES.SVG]: ['.svg'],
  [IMAGE_TYPES.TIF]: ['.tif'],
  [IMAGE_TYPES.TIFF]: ['.tiff'],
  [IMAGE_TYPES.XBM]: ['.xbm'],
  [IMAGE_TYPES.XPM]: ['.xpm'],
  [IMAGE_TYPES.XWD]: ['.xwd'],

  [IMAGE_TYPES['7Z']]: ['7Z'],
  [IMAGE_TYPES.ZIP]: ['ZIP'],
  [IMAGE_TYPES.RAR]: ['RAR'],
};
