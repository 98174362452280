import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './modules/global';
import user from './modules/user';
import auth from './modules/auth';
import announcements from './modules/announcements';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    alert,
    user,
    auth,
    announcements,
  },
  actions: {
    clearStorage(store) {
      store.dispatch('user/clearUserdata');
    },
  },
});
