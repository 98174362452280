import api from '@/api/announcements';

export default {
  namespaced: true,

  state: {
    hasUnread: false,
  },

  mutations: {
    setHasUnread(state, hasUnread) {
      state.hasUnread = hasUnread;
    },
  },

  actions: {
    async getHasUnread({ commit }) {
      try {
        const hasUnread = await api.hasUnread();

        commit('setHasUnread', hasUnread);

        return hasUnread;
      } catch (err) {
        throw err;
      }
    },
  },
};
