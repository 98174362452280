<template>
  <a
    href="/announcements"
    class="notifications-link"
    data-testid="elem_u_000152"
  >
    <v-icon
      color="#737373"
      data-testid="elem_u_000153"
    >
      notifications_none
    </v-icon>
    <div
      v-if="hasUnread"
      class="has-unread"
      data-testid="elem_u_003027"
    ></div>
  </a>
</template>

<script>
export default {
  name: 'Notifications',

  data() {
    return {
      hasUnread: false,
    };
  },

  async mounted() {
    await this.$store.dispatch('announcements/getHasUnread');
  },

  created() {
    this.$store.watch(
      (state) => state.announcements.hasUnread,
      () => {
        this.hasUnread = this.$store.state.announcements.hasUnread;
      },
    );
  },
};
</script>

<style lang="scss">
.notifications-link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background-color: var(--color-grey-280);
  text-decoration: none;
  border-radius: 50px;
}

.has-unread {
  display: block;
  box-sizing: content-box;
  width: 7px;
  height: 7px;
  background-color: var(--color-primary);
  border-radius: 50px;
  position: absolute;
  top: 5px;
  right: 0;

  &::after {
    content: '';
    height: 100%;
    width: 100%;
    border-radius: 50%;
    border: 3px solid #242424;
    position: absolute;
    top: -3px;
    left: -3px;
  }
}
</style>
