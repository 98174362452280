import axios from 'axios';
import store from '../store';

const baseURL = process.env.VUE_APP_BASE_URL;
const refreshPath = process.env.VUE_APP_API_REFRESH_TOKEN_URL || '/v1/clients/web/admin/refresh';

export default function () {
  axios.defaults.baseURL = baseURL;
  axios.defaults.timeout = 10000 * 6 * 30;

  axios.interceptors.request.use(
    (config) => {
      let token = localStorage.getItem('token') || null;
      if (token) {
        config.headers['Authorization'] = token;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      const refreshToken = localStorage.getItem('refreshToken') || null;

      if (error.message === 'canceled') {
        return Promise.reject({
          message: originalRequest.signal.reason,
        });
      }

      if (error.response) {
        if (error.response.status === 401 && !originalRequest.url.split('/').includes('login')) {
          if (originalRequest.url === refreshPath || !refreshToken) {
            store.dispatch('auth/logout', true);
          } else if (!originalRequest._retry) {
            originalRequest._retry = true;

            return store
              .dispatch('auth/getFreshToken', {
                refresh_token: refreshToken,
              })
              .then((res) => {
                return axios(originalRequest);
              });
          }
        }

        return Promise.reject(error.response);
      } else if (error.request) {
        return Promise.reject({
          message: 'An error has occurred while processing your request. Please try again later.',
        });
      } else {
        return Promise.reject({
          message: 'An error has occurred while processing your request. Please try again later.',
        });
      }
    },
  );
}
