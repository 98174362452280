<template>
  <div
    class="date-picker d-inline-block"
    data-testid="elem_u_000023"
  >
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      offset-y
      :nudge-right="5"
      transition="scale-transition"
      min-width="290px"
      data-testid="elem_u_000842"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          @click:clear="$emit('clear')"
          @click:append="iconClick"
          :value="dateFormatted(value)"
          :label="label"
          :loading="loading"
          :placeholder="placeholder"
          :clearable="clearable"
          append-icon="calendar_month"
          readonly
          v-on="on"
          hide-details
          class="unzipvr-input"
          :rules="rules"
          :error-messages="errorMessages"
          :validate-on-blur="validateOnBlur"
          @focus="(val) => $emit('focus', val)"
          :disabled="disabled"
          ref="datePickerInput"
          data-testid="elem_u_000843"
        />
      </template>
      <v-date-picker
        :value="value"
        @input="(val) => $emit('input', val)"
        color="#f4db64"
        header-color="#2f2f2f"
        event-color="#2f2f2f"
        :min="min"
        :max="max"
        @change="onChange"
        data-testid="elem_u_000844"
        :disabled="disabled"
      />
    </v-menu>
  </div>
</template>

<script>
import moment from 'moment-timezone';

export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    max: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    errorMessages: {
      type: String | Array,
      default() {
        return [];
      },
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menu: false,
    };
  },
  methods: {
    dateFormatted(date) {
      return date ? moment(date).format('MMM D, YYYY') : '';
    },
    onChange(event) {
      (this.menu = false), this.$emit('change', event);
    },
    iconClick() {
      this.$refs.datePickerInput.focus();
      this.menu = !this.menu;
    },
  },
};
</script>

<style lang="scss">
.date-picker {
  .theme--dark.v-icon::before {
    color: var(--color-grey-600) !important;
    font-size: 21px;
  }
  input {
    cursor: pointer;
  }
}
</style>
