<template>
  <div data-testid="elem_u_000845">
    <slot
      :on="{ click: open }"
      :src="src"
      :alt="alt"
    />

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="unset"
      data-testid="elem_u_000846"
    >
      <div
        class="fullscreen-view"
        data-testid="elem_u_000024"
      >
        <slot
          name="tags"
          :on="{ click: close }"
        ></slot>

        <div class="image-wrapper">
          <template v-if="type === 'img'">
            <v-img
              contain
              :height="imageHeight"
              position="top"
              :src="src"
              :alt="alt"
              @load="isImageLoaded = true"
            />
            <div
              v-if="!isImageLoaded"
              class="progress-circular__wrapper"
            >
              <v-progress-circular
                :size="50"
                color="#F4DB64"
                indeterminate
                data-testid="elem_u_003210"
              />
            </div>
          </template>
          <video
            v-else
            class="fullscreen-view__video"
            :src="src"
            controls
          ></video>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'img',
    },
  },

  data() {
    return {
      dialog: false,
      isImageLoaded: false,
    };
  },

  computed: {
    imageHeight() {
      return this.$vuetify.breakpoint.width < 1000
        ? this.$vuetify.breakpoint.width < 600
          ? '281px'
          : '474px'
        : '746px';
    },
  },

  watch: {
    dialog(value) {
      if (!value) {
        this.isImageLoaded = false;
      }
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.isImageLoaded = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-view {
  position: relative;
  width: 820px;
  height: 820px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    width: 538px;
    height: 538px;
  }

  @media (max-width: 600px) {
    width: 345px;
    height: 345px;
  }
}

.image-wrapper {
  object-fit: contain;
  max-width: 100%;
  max-height: 746px;

  video {
    border-radius: 10px;
    overflow: hidden;
  }

  @media (max-width: 1000px) {
    max-height: 474px;
  }

  @media (max-width: 600px) {
    max-height: 281px;
  }
}

.progress-circular__wrapper {
  position: absolute;
  top: 74px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullscreen-view__video {
  display: block;
  width: 100%;
  height: auto;
}
</style>
