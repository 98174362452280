<template>
  <UploadDialog
    :accept="accept"
    :size="size"
    #default="{ on }"
    @load-file="(image) => $emit('upload', image)"
    data-testid="elem_u_003173"
  >
    <div
      class="upload-image-empty"
      data-testid="elem_u_000730"
    >
      <v-card
        color="transparent"
        data-testid="elem_u_000731"
        flat
        v-on="on"
      >
        <v-card-text
          class="pa-0"
          data-testid="elem_u_000732"
        >
          <div
            class="upload-image-empty-content"
            data-testid="elem_u_000733"
          >
            <div
              class="upload-image-icon"
              data-testid="elem_u_000734"
            >
              <v-icon
                color="#8D8D8D"
                data-testid="elem_u_000736"
              >
                add
              </v-icon>
            </div>
            <div
              class="upload-image-title"
              :class="{ required }"
              data-testid="elem_u_000735"
            >
              {{ label }}
            </div>
            <div
              data-testid="elem_u_000737"
              class="upload-image-hint"
              v-html="hint"
            />
          </div>
        </v-card-text>
      </v-card>
    </div>
  </UploadDialog>
</template>

<script>
import UploadDialog from '@/components/common/Uploads/UploadDialog';

export default {
  components: {
    UploadDialog,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 2 * 1024 * 1024, // 2Mb
    },
    required: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: 'Available file types: .jpg, .jpeg, .png, .svg, .gif.<br>Max file size 2 Mb.',
    },
  },
};
</script>

<style lang="scss">
.upload-image-empty {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  overflow: hidden;
  .v-card__text {
    padding: 0 !important;
  }
}

.upload-image-empty-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 29px;
}

.upload-image-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  background: var(--color-grey-300);
  border-radius: 50px;
}

.upload-image-title {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: var(--color-grey-900);
  text-transform: capitalize;
  letter-spacing: normal;
  &.required {
    &::after {
      content: ' *';
      color: var(--color-warning);
    }
  }
}

.upload-image-hint {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-grey-500);
  letter-spacing: normal;
}
</style>
