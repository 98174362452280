<template>
  <div
    class="image-item-controls"
    :class="{ 'image-item-controls__vertical': vertical }"
    data-testid="elem_u_000745"
  >
    <UploadDialog
      :accept="accept"
      :size="size"
      #default="{ on: uploadOn }"
      @load-file="(image) => $emit('upload', image)"
      data-testid="elem_u_003174"
    >
      <v-tooltip
        bottom
        offset-overflow
        max-width="300"
        :open-delay="300"
        z-index="300"
        data-testid="elem_u_003175"
      >
        <template #activator="{ on: tooltipOn, attrs }">
          <v-btn
            v-if="uploadable"
            icon
            class="image-item-controls__button"
            v-on="commonOn(uploadOn, tooltipOn)"
            large
            data-testid="elem_u_000746"
          >
            <v-icon
              size="24"
              color="#8D8D8D"
              data-testid="elem_u_000747"
            >
              add
            </v-icon>
          </v-btn>
        </template>
        <span
          v-html="hint"
          data-testid="elem_u_000748"
        />
      </v-tooltip>
    </UploadDialog>

    <v-tooltip
      bottom
      offset-overflow
      max-width="300"
      :open-delay="300"
      z-index="300"
      data-testid="elem_u_000749"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          v-if="clearable"
          icon
          class="image-item-controls__button"
          @click="$emit('clear')"
          v-on="on"
          large
          data-testid="elem_u_000750"
        >
          <v-icon
            size="24"
            color="#8D8D8D"
            data-testid="elem_u_000751"
          >
            delete
          </v-icon>
        </v-btn>
      </template>
      <span data-testid="elem_u_000752"> Delete </span>
    </v-tooltip>
  </div>
</template>

<script>
import UploadDialog from '@/components/common/Uploads/UploadDialog';

export default {
  components: {
    UploadDialog,
  },

  props: {
    accept: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 2 * 1024 * 1024, // 2Mb
    },
    hint: {
      type: String,
      default: '',
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    uploadable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hover: false,
    };
  },

  methods: {
    commonOn(uploadOn, tooltipOn) {
      return Object.assign(uploadOn, tooltipOn);
    },
  },
};
</script>

<style lang="scss">
.image-item-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  z-index: 5;

  &__vertical {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
}

.image-item-controls__button {
  background: var(--color-grey-300);
}
</style>
