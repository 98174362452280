<template>
  <div data-testid="elem_u_000778">
    <v-file-input
      ref="fileInput"
      :accept="accept"
      hide-input
      @change="saveFile"
      class="d-none"
      data-testid="elem_u_000779"
    />
    <slot :on="{ click: open, upload: open }" />
    <PopupError
      v-if="requirementsError"
      ref="popupError"
    />
  </div>
</template>

<script>
import PopupError from './PopupError.vue';

export default {
  components: {
    PopupError,
  },

  props: {
    payload: {
      default: '',
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    size: {
      type: Number,
      default: 2 * 1024 * 1024, // 2Mb
    },
    requirementsError: {
      type: String,
      default: '',
    },
  },
  methods: {
    open() {
      this.$refs.fileInput.$refs.input.click();
    },

    async saveFile(image) {
      if (!image) return;

      let fulfilledConditions = true;

      if (this.accept === 'image/*') {
        if (image.type.split('/')[0] !== 'image') {
          fulfilledConditions = false;
        }
      } else if (!this.accept.includes(image.type)) {
        fulfilledConditions = false;
      }

      if (image.size > this.size) {
        fulfilledConditions = false;
      }

      if (fulfilledConditions) {
        this.$emit('load-file', { image, payload: this.payload });
        this.$refs.fileInput.$refs.input.value = '';
      } else {
        if (this.requirementsError) {
          this.$refs.popupError.open(this.requirementsError);
        } else {
          this.$alert.notify('The file does not meet the requirements', 'error');
        }
      }
    },
  },
};
</script>
